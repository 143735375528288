import { memo, useContext, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'antd'
import { Button, BUTTON_VARIANTS } from 'components/Button'
import { FintrosLogo } from 'components/FintrosLogo'
import { Text } from 'components/Text'
import { IoMdMoon, IoMdSunny } from 'react-icons/io'
import { useTheme } from 'styled-components'
import { Wrapper } from 'styles/styleSystem/wrapper'
import {
  LoggedOutHeaderWrapper,
  LoggedOutHeaderContent,
  LoggedOutHeaderLogoWrapper,
} from './styles'
import {
  MdArrowForward,
  MdMenu,
  MdQuestionAnswer,
  MdKeyboardBackspace,
  MdDescription,
  MdPieChart,
} from 'react-icons/md'
import { Divider } from 'components/Divider'
import SearchBar from 'components/SearchBar'
import {
  HiOutlineSearchCircle,
  HiCurrencyDollar,
  HiOfficeBuilding,
  HiLibrary,
} from 'react-icons/hi'
import { useWindowSize } from 'contexts/WindowSizeContext'
import { LoggedOutHeaderNavDrawer } from './LoggedOutHeaderNavDrawer'
import { PreferenceContext } from 'contexts/PreferenceContext'
import { themes } from 'styles/themes/themes'
import { RedirectButtonWrapper } from 'components/Button/RedirectButtonWrapper'
import { RedirectPrimaryButton } from 'components/Button/RedirectPrimaryButton'
import { useAutoHideMobileSearchBar } from 'contexts/MobileSearchBarContext'
import { LoggedOutBanner } from 'components/LoggedOutBanner'
import { TextDropdown } from 'components/Dropdown/TextDropdown'
import { FaHandshake } from 'react-icons/fa'

interface LogOutBannerProps {
  forceShowBannerOnLogout?: boolean
}
export const LoggedOutHeader: React.FC<LogOutBannerProps> = ({
  forceShowBannerOnLogout,
}) => {
  const [showBanner, setShowBanner] = useState(forceShowBannerOnLogout || false)

  const [onCloseSuggestionDropdown, setOnCloseSuggestionDropdown] =
    useState(null)
  const [isSuggestionDropdownOpen, setIsSuggestionDropdownOpen] =
    useState(false)
  const [mobileNavDrawerOpen, setMobileNavDrawerOpen] = useState(false)
  const { hideMobileBar } = useAutoHideMobileSearchBar()
  const theme = useTheme()
  const appWrapperRef = useRef(null)
  const { isMobile } = useWindowSize()
  const { updateProperty } = useContext(PreferenceContext)

  const changeTheme = async () => {
    const updatedTheme = theme.is_light_mode
      ? themes.find((theme) => theme.name === 'midnight_blue')
      : themes.find((theme) => theme.name === 'classic_light')
    updateProperty('themeConfig', updatedTheme)
  }

  useEffect(() => {
    setShowBanner(!hideMobileBar)
  }, [hideMobileBar])

  useEffect(() => {
    const app = document.querySelector('.test')
    appWrapperRef.current = app
  }, [])

  const getOnCloseSuggestionDropdown = (fn) => {
    setOnCloseSuggestionDropdown(() => fn)
  }

  useEffect(() => {
    setShowBanner(forceShowBannerOnLogout || showBanner)
  }, [forceShowBannerOnLogout, showBanner])

  return (
    <>
      <LoggedOutBanner
        showBanner={showBanner}
        setShowBanner={setShowBanner}
        bannerText="Note: Due to unprecedented demand, new users may be waitlisted in priority sequence!"
      />
      <LoggedOutHeaderWrapper $showBanner={showBanner} hide={hideMobileBar}>
        <LoggedOutHeaderContent>
          {!isSuggestionDropdownOpen && (
            <RedirectButtonWrapper href="/">
              <LoggedOutHeaderLogoWrapper>
                <Wrapper
                  display="flex"
                  alignItems="center"
                  cursor="pointer"
                  ml={isMobile ? 0 : 10}
                  spacing={10}
                  horizontal
                >
                  <FintrosLogo iconOnly />
                  {!isMobile && <Text size="xl">Fintros</Text>}
                </Wrapper>
              </LoggedOutHeaderLogoWrapper>
            </RedirectButtonWrapper>
          )}
          {isSuggestionDropdownOpen && (
            <LoggedOutHeaderLogoWrapper onClick={onCloseSuggestionDropdown}>
              <Wrapper
                display="flex"
                alignItems="center"
                cursor="pointer"
                ml={isMobile ? 0 : 10}
              >
                <MdKeyboardBackspace size={24} color={theme.primary} />
              </Wrapper>
            </LoggedOutHeaderLogoWrapper>
          )}
          <Wrapper width="400px" ml={isMobile ? 0 : 20} mr={20}>
            <SearchBar
              placeholder="Search fintros..."
              loggedOut
              hideMobile={false}
              getOnCloseSuggestionDropdown={getOnCloseSuggestionDropdown}
              getIsSuggestionDropdownOpened={setIsSuggestionDropdownOpen}
            />
          </Wrapper>
          {!isMobile && (
            <>
              <Wrapper
                display="flex"
                alignItems="center"
                spacing={40}
                horizontal
              >
                <TextDropdown
                  label="Employers"
                  leftAlign
                  size="lg"
                  arrow
                  fadeIn
                  hoverable
                  dropdownPaddingTop="20px"
                >
                  <EmployersDropdown />
                </TextDropdown>
                <TextDropdown
                  label="Candidates"
                  leftAlign
                  size="lg"
                  arrow
                  fadeIn
                  hoverable
                  dropdownPaddingTop="20px"
                >
                  <CandidatesDropdown />
                </TextDropdown>
                <Tooltip
                  title={
                    theme.is_light_mode
                      ? 'Try our dark mode!'
                      : 'Try our light mode!'
                  }
                >
                  <Wrapper
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    onClick={changeTheme}
                  >
                    {theme.is_light_mode ? (
                      <IoMdMoon color={theme.primary} />
                    ) : (
                      <IoMdSunny color={theme.primary} />
                    )}
                  </Wrapper>
                </Tooltip>
              </Wrapper>
              <Wrapper
                display="flex"
                alignItems="center"
                spacing={20}
                horizontal
                mr={10}
              >
                <RedirectButtonWrapper href="/login">
                  <Button variant={BUTTON_VARIANTS.transparent}>
                    <Text size="lg">Login</Text>
                  </Button>
                </RedirectButtonWrapper>
                <RedirectPrimaryButton
                  href="/join"
                  variant={BUTTON_VARIANTS.primary}
                  label="Sign up"
                  size="lg"
                />
              </Wrapper>
            </>
          )}
          {isMobile && (
            <Wrapper cursor="pointer" display="flex" alignItems="center">
              <MdMenu
                size={25}
                color={theme.primary}
                onClick={() => setMobileNavDrawerOpen(true)}
              />
            </Wrapper>
          )}
        </LoggedOutHeaderContent>
        <LoggedOutHeaderNavDrawer
          isOpen={mobileNavDrawerOpen}
          setIsOpen={setMobileNavDrawerOpen}
        />
      </LoggedOutHeaderWrapper>
    </>
  )
}

const DropdownSection = ({
  section,
  description,
  icon = null,
  url = null,
  customUrl = null,
}) => {
  const [hovered, setHovered] = useState(false)
  const Icon = icon
  const theme = useTheme()

  const handleLinkClick = () => {
    if (customUrl) {
      return window.open(customUrl, '_blank')
    }
  }

  return (
    <Wrapper
      width="260px"
      spacing={7}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleLinkClick}
    >
      <RedirectButtonWrapper href={url ? url : null}>
        <Wrapper display="flex" alignItems="center" spacing={10} horizontal>
          {icon && <Icon color={hovered ? theme.highlight : theme.secondary} />}
          <Text size="xl" color="primary">
            {section}
          </Text>
          {hovered && <MdArrowForward color={theme.primary} />}
        </Wrapper>
        <Wrapper paddingTop={2}>
          <Text
            size="lg"
            color="secondary"
            weight="light"
            leading="relaxed"
            breakSpaces
            marginBottom={10}
          >
            {description}
          </Text>
        </Wrapper>
      </RedirectButtonWrapper>
    </Wrapper>
  )
}

const CandidatesDropdown = memo(() => {
  return (
    <Wrapper padding={20} spacing={30}>
      {/* <DropdownSection
        section="Features"
        description="Learn why Fintros is trusted by tens of thousands of finance leaders"
        icon={MdOutlineFeaturedPlayList}
        url="/features"
      /> */}
      <DropdownSection
        section="Search Jobs"
        description="Anonymously Apply&trade; to the world's best jobs"
        icon={HiOutlineSearchCircle}
        url="/search/jobs"
      />
      <DropdownSection
        section="Search Companies"
        description="Search 1K+ top teams that hire with the Fintros Anonymous Resume&trade;"
        icon={HiOfficeBuilding}
        url="/search/companies"
      />
      <DropdownSection
        section="Search Salaries"
        description="Unlimited 24/7 free access to the industry's most accurate salary guide"
        icon={HiCurrencyDollar}
        url="/search/salaries"
      />
      <DropdownSection
        section="Frequently Asked Questions"
        description="Learn about the world's first Anonymous Apply&trade; Job Application, our approach towards worldwide recruitment equality and more"
        icon={MdQuestionAnswer}
        url="/faq"
      />
      <DropdownSection
        section="Sample Inclusive Resume™"
        description="Take a look at our example resume that shows how our anonymization works"
        icon={MdDescription}
        url="/examples/resume"
      />
      <Divider margin={30} />
      <DropdownSection
        section="Library"
        description="The latest in hiring automation, job discovery, workplace equality, and more"
        icon={HiLibrary}
        url="/library"
      />
    </Wrapper>
  )
})

const EmployersDropdown = memo(() => {
  return (
    <Wrapper padding={20} spacing={30}>
      <DropdownSection
        section="Hire With Fintros"
        description="Learn why Fintros is used daily by leading HR teams"
        icon={FaHandshake}
        url="/hire"
      />
      <DropdownSection
        section="Frequently Asked"
        description="Learn why Fintros AI is loved by leading financial institutions"
        icon={MdQuestionAnswer}
        url="/faq?tab=employer"
      />
      <DropdownSection
        section="Demographics"
        description="See the breakdown of our candidates"
        icon={MdPieChart}
        url="/demographics"
      />
      {/* <DropdownSection
        section="Pricing"
        description="Pricing options to fit all organizations"
        icon={MdMonetizationOn}
        url="/pricing"
      /> */}
      <Divider margin={30} />
      <DropdownSection
        section="Request A Demo"
        description="Create an employer profile"
        customUrl="https://beta.fintros.com/join"
      />
    </Wrapper>
  )
})
